<template>
  <h3 class="text-center">
    Name und Titel
    <h6 class="mt-2">
      (dev only:
      <button @click.prevent="generateMockUp" class="btn btn-success p-2 mx-1">
        <font-awesome-icon icon="user-plus" class="px-0" />
      </button>
      <button @click.prevent="emptyForm" class="btn btn-danger p-2 mx-1">
        <font-awesome-icon icon="user-minus" class="px-0" /></button
      >)
    </h6>
  </h3>
  <form
    class="needs-validation"
    @submit.prevent="validateConsentData"
    novalidate
  >
    <div class="form-floating mb-4">
      <input
        type="text"
        class="form-control"
        :class="[
          firstNameError === 1 ? 'is-invalid' : '',
          firstNameError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        placeholder="Franz"
        autocomplete="given-name"
        v-model="store.subject.firstName"
      />
      <label for="floatingInput">Vorname</label>
      <div class="invalid-feedback">
        Bitte geben Sie einen gültigen Vornamen ein.
      </div>
    </div>
    <div class="form-floating mb-4">
      <input
        type="text"
        class="form-control"
        :class="[
          lastNameError === 1 ? 'is-invalid' : '',
          lastNameError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        autocomplete="family-name"
        placeholder="Musterfrau"
        v-model="store.subject.lastName"
      />
      <label for="floatingInput">Nachname</label>
      <div class="invalid-feedback">
        Bitte geben Sie einen gültigen Nachnamen ein.
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-floating mb-2">
          <input
            type="text"
            class="form-control"
            id="preNomenTitle"
            placeholder="Mag."
            v-model="store.subject.preNomen"
          />
          <label for="preNomenTitle" style="font-size: 10px"
            >Vorangestellter Titel</label
          >
        </div>
      </div>
      <div class="col-6">
        <div class="form-floating mb-2">
          <input
            type="text"
            class="form-control"
            id="postNomenTitle"
            placeholder="MSc."
            v-model="store.subject.postNomen"
          />
          <label for="postNomenTitle" style="font-size: 10px"
            >Nachgestellter Titel</label
          >
        </div>
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
      :disabled="isFormInvalid"
    >
      Weiter
    </button>
  </form>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed } from "vue";
import { samplePersons } from "@/assets/samplePersons";
const store = dhRegistrationStore();
const firstNameError = ref(0);
const lastNameError = ref(0);

const isFormInvalid = computed(() => {
  return store.subject.firstName === "" || store.subject.lastName === "";
});

function validateNames() {
  store.subject.firstName = store.subject.firstName.trim();
  const nameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s,.'-]{2,}$/gimu;
  if (!nameRegex.test(store.subject.firstName)) {
    firstNameError.value = 1;
  } else {
    firstNameError.value = 2;
  }
  store.subject.lastName = store.subject.lastName.trim();
  nameRegex.lastIndex = 0;
  if (!nameRegex.test(store.subject.lastName)) {
    lastNameError.value = 1;
  } else {
    lastNameError.value = 2;
  }
}

function sanitizeAcademicTitle(input) {
  return input.replace(/[^a-zA-Z0-9 .,\'\-]/g, "");
}

function validateConsentData() {
  validateNames();

  if (firstNameError.value === 2 && lastNameError.value === 2) {
    if (store.subject.age < 65) {
      store.abortAgeError = 1;
      store.step = 0;
    } else {
      store.subject.preNomen = sanitizeAcademicTitle(store.subject.preNomen);
      store.subject.postNomen = sanitizeAcademicTitle(store.subject.postNomen);
      store.step++;
    }
  }
}

function generateMockUp() {
  let randomPerson =
    samplePersons[Math.floor(Math.random() * samplePersons.length)];
  store.subject.firstName = randomPerson.firstName;
  store.subject.lastName = randomPerson.lastName;
}

function emptyForm() {
  store.subject.firstName = "";
  store.subject.lastName = "";
}
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
