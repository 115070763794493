<template>
  <div
    v-if="store.step <= 40"
    class="navbar navbar-expand-lg bg-white pt-3 pb-1 shadow-xl"
  >
    <div class="row d-flex w-100 no-gutters align-items-center">
      <div class="col-7 pe-0">
        <BackButton
          v-if="store.step != 5 && store.step != 11 && store.step != 23"
        />
        <RestartButton v-if="(store.step !== 0) & (store.step <= 40)" />
      </div>
      <div class="col-5 text-center pe-0">
        <ProgressBar />
      </div>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
import BackButton from "@/components/navigation/BackButton.vue";
import ProgressBar from "@/components/utils/ProgressBar.vue";
import RestartButton from "@/components/navigation/RestartButton.vue";

const store = dhRegistrationStore();
</script>

<style scoped>
.progress {
  height: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.progress-bar {
  background-color: #142b58;
}
</style>
