<template>
  <h2 class="mb-0">Werfen Sie die Münze!</h2>
  <div
    ref="animationContainer"
    @click.prevent="playAnimation"
    :disabled="coinTossed"
    :class="[coinTossed ? 'div-disabled' : '']"
  ></div>
  <button
    @click.prevent="playAnimation"
    :class="[coinTossed ? 'd-none' : '']"
    :disabled="coinTossed"
    class="btn btn-proceed btn-lg w-100"
  >
    Münze werfen
  </button>
</template>

<script>
import lottie from "lottie-web";
import animationData from "@/assets/coinToss.json";
import { ref } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";

export default {
  setup() {
    const store = dhRegistrationStore();
    const coinTossed = ref(false);
    const hideButton = () => {
      coinTossed.value = true;
    };
    return { store, coinTossed, hideButton };
  },
  mounted() {
    this.animation = lottie.loadAnimation({
      container: this.$refs.animationContainer,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData,
    });
  },
  methods: {
    playAnimation() {
      this.animation.setSpeed(0.9);
      this.animation.play();
      this.hideButton();
      this.store.subject.cohort = "1"; // this is the "fake" randomisation
      this.store.status = "completed";
      this.store.constructFHIRBundle();
      setTimeout(() => {
        this.store.step++;
      }, 4750);
    },
  },
};
</script>

<style scoped>
.div-disabled {
  pointer-events: none;
}
</style>
