<template>
  <h2>Dürfen wir vorstellen?</h2>
  <div class="row my-4 justify-content-center align-items-center">
    <div class="text-center">
      Unser Dr. Medicus wird Sie durch die Fragen begleiten und Sie
      unterstützen, wenn Sie Hilfe brauchen.
    </div>
  </div>
  <div class="row mt-3 justify-content-center align-items-center">
    <div class="col-5">
      <a
        tabindex="0"
        ref="popoverLink"
        data-bs-toggle="popover"
        data-bs-placement="bottom"
        data-bs-offset="0px,50px"
        data-bs-content="Ich bin Dr. Medicus und freue mich sehr Sie kennenzulernen. Ich darf Sie durch die Registrierung führen und Ihnen wichtige Informationen und Tipps zum Thema Herzgesundheit geben!"
        @click="togglePopover"
      >
        <img src="img/medicusStart.png" class="img-responsive fit-image"
      /></a>
    </div>
  </div>
  <div class="mt-3 text-center">
    Klicken Sie auf Dr. Medicus für gute Infos und Tipps!
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Popover } from "bootstrap";

export default {
  setup() {
    const popoverLink = ref(null);
    let popoverInstance = null;

    const togglePopover = (event) => {
      event.stopPropagation();
      if (popoverInstance) {
        popoverInstance.toggle();
      }
    };

    const hidePopover = () => {
      if (popoverInstance) {
        popoverInstance.hide();
      }
    };

    onMounted(() => {
      if (popoverLink.value) {
        popoverInstance = new Popover(popoverLink.value, {
          trigger: "manual",
        });
      }
      document.addEventListener("click", hidePopover);
    });

    return {
      popoverLink,
      togglePopover,
    };
  },
};
</script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}
</style>
