<template>
  <h2>Fast geschafft!</h2>
  <div class="text-center">
    Blieben Sie dran - wir möchten Ihnen nur noch ein paar Fragen stellen, bevor
    Sie an <b>Pulskontrolle</b> teilnehmen können.<br /><br />
    <div class="row my-4">
      <div class="col-7">
        Mit diesen wichtigen Fragen zu Ihrer Gesundheit können wir Ihr
        individuelles Risiko abschätzen.
      </div>
      <div class="col-5 text-center">
        Dauer: <br />5 Minuten<br />
        <font-awesome-icon
          icon="fa-clock"
          style="font-size: 2em"
          class="mt-3"
        />
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
