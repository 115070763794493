<template>
  <h3 class="text-center">
    Wohnadresse<br />
    <h6>
      (dev only:
      <button @click.prevent="generateMockUp" class="btn btn-success p-2 mx-1">
        <font-awesome-icon icon="user-plus" class="px-0" />
      </button>
      <button @click.prevent="emptyForm" class="btn btn-danger p-2 mx-1">
        <font-awesome-icon icon="user-minus" class="px-0" /></button
      >)
    </h6>
  </h3>
  <form
    class="needs-validation"
    @submit.prevent="validateConsentData"
    novalidate
  >
    <div class="form-floating mb-2">
      <input
        type="text"
        class="form-control"
        id="floatingInput"
        placeholder="Mustermannplatz"
        v-model="store.subject.address.street"
        ref="streetRef"
        @change="parseAddress()"
        autocomplete="street-address"
      />
      <label for="floatingInput">Straße</label>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="form-floating mb-2">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="12"
            v-model="store.subject.address.streetNr"
          />
          <label for="floatingInput">Nr.</label>
        </div>
      </div>
      <div class="col-4">
        <div class="form-floating mb-2">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="1"
            v-model="store.subject.address.blockNr"
          />
          <label for="floatingInput">Stiege</label>
        </div>
      </div>
      <div class="col-4">
        <div class="form-floating mb-2">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="27"
            v-model="store.subject.address.doorNr"
          />
          <label for="floatingInput">Tür</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-floating mb-4">
          <input
            type="number"
            class="form-control"
            :class="[
              zipError === 1 ? 'is-invalid' : '',
              zipError === 2 ? 'is-valid' : '',
            ]"
            id="floatingInput"
            placeholder="1010"
            v-model="store.subject.address.ZIP"
            autocomplete="postal-code"
          />
          <label for="floatingInput">PLZ</label>
          <div class="invalid-feedback">
            Bitte geben Sie eine österreichische Postleitzahl an (z.B. 6020).
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-floating mb-4">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="Vienna"
            v-model="store.subject.address.city"
            autocomplete="address-level2"
          />
          <label for="floatingInput">Stadt</label>
        </div>
      </div>
    </div>
    <button
      type="submit"
      class="btn btn-proceed btn-lg w-100 my-3"
      :disabled="isFormInvalid"
    >
      Weiter
    </button>
  </form>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed, onMounted, onUnmounted } from "vue";
import { zipAT } from "@/assets/zipCodes.js";
import { samplePersons } from "@/assets/samplePersons";
const store = dhRegistrationStore();
const zipError = ref(0);

const isFormInvalid = computed(() => {
  return (
    store.subject.address.street === "" ||
    store.subject.address.streetNr === "" ||
    store.subject.address.ZIP === "" ||
    store.subject.address.city === ""
  );
});

function validateZIP() {
  if (
    !zipAT.includes(store.subject.address.ZIP) ||
    store.subject.address.ZIP !== parseInt(store.subject.address.ZIP, 10)
  ) {
    zipError.value = 1;
  } else {
    zipError.value = 2;
  }
}

function validateConsentData() {
  validateZIP();
  store.subject.address.street = store.subject.address.street.trim();
  store.subject.address.city = store.subject.address.city.trim();
  store.subject.address.streetNr = store.subject.address.streetNr.trim();
  store.subject.address.blockNr = store.subject.address.blockNr.trim();
  store.subject.address.doorNr = store.subject.address.doorNr.trim();
  if (zipError.value === 2) {
    store.step++;
  }
}

function generateMockUp() {
  let randomPerson =
    samplePersons[Math.floor(Math.random() * samplePersons.length)];
  store.subject.address.street = randomPerson.street;
  store.subject.address.streetNr = randomPerson.streetNr;
  store.subject.address.blockNr = randomPerson.blockNr;
  store.subject.address.doorNr = randomPerson.doorNr;
  store.subject.address.ZIP = randomPerson.ZIP;
  store.subject.address.city = randomPerson.city;
  store.subject.address.country = randomPerson.country;
}

function emptyForm() {
  Object.keys(store.subject.address).forEach(
    (i) => (store.subject.address[i] = "")
  );
}

// Prepared Google Places Autocomplete API, feature postponed
// const streetRef = ref();

// let autocomplete;

// onMounted(() => {
//   autocomplete = new google.maps.places.Autocomplete(streetRef.value, {
//     types: ["address"],
//     fields: ["address_components"],
//   });
//   autocomplete.setComponentRestrictions({
//     country: ["at"],
//   });

//   google.maps.event.addListener(autocomplete, "place_changed", () => {
//     let enteredPlace = autocomplete.getPlace();
//     if ("address_components" in enteredPlace) {
//       store.subject.address.street = getComponentsByType(
//         enteredPlace.address_components,
//         "route"
//       );
//       store.subject.address.streetNr = getComponentsByType(
//         enteredPlace.address_components,
//         "street_number"
//       );
//       store.subject.address.city = getComponentsByType(
//         enteredPlace.address_components,
//         "locality"
//       );
//       store.subject.address.ZIP = parseInt(
//         getComponentsByType(enteredPlace.address_components, "postal_code")
//       );
//       store.subject.address.city = getComponentsByType(
//         enteredPlace.address_components,
//         "locality"
//       );
//       store.subject.address.country = getComponentsByType(
//         enteredPlace.address_components,
//         "country"
//       );
//     }
//   });
// });

// onUnmounted(() => {
//   if (autocomplete) {
//     google.maps.event.clearInstanceListeners(autocomplete);
//   }
// });

// function getComponentsByType(components, type) {
//   let tempComponent = components.filter((component) =>
//     component.types.includes(type)
//   );
//   try {
//     return tempComponent[0].long_name;
//   } catch {
//     if (type === "postal_code") {
//       return null;
//     } else {
//       return "";
//     }
//   }
// }

function parseAddress() {
  const parts = store.subject.address.street.split(/[ \/]+/);
  if (parts.length === 3) {
    store.subject.address.street = parts[0];
    store.subject.address.streetNr = parts[1];
    store.subject.address.doorNr = parts[2];
    store.subject.address.blockNr = "";
  } else if (parts.length === 4) {
    store.subject.address.street = parts[0];
    store.subject.address.streetNr = parts[1];
    store.subject.address.blockNr = parts[2];
    store.subject.address.doorNr = parts[3];
  }
}
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
</style>
