<template>
  <transition>
    <section v-if="store.step === 5">
      <EligibilityStartScreen />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 6">
      <EligibilityMedicusIntroScreen />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 7">
      <EligibilityAfibScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 8">
      <EligibilityAnticoagulantQuestionScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 9">
      <EligibilityAnticoagulantScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 10">
      <EligibilityConfirmationScreen />
    </section>
  </transition>
</template>

<script setup>
import EligibilityStartScreen from "@/components/screens/eligibility/EligibilityStartScreen.vue";
import EligibilityAnticoagulantScreen from "@/components/screens/eligibility/EligibilityAnticoagulantScreen.vue";
import EligibilityAnticoagulantQuestionScreen from "@/components/screens/eligibility/EligibilityAnticoagulantQuestionScreen.vue";
import EligibilityAfibScreen from "@/components/screens/eligibility/EligibilityAfibScreen.vue";
import EligibilityConfirmationScreen from "@/components/screens/eligibility/EligibilityConfirmationScreen.vue";
import EligibilityMedicusIntroScreen from "@/components/screens/eligibility/EligibilityMedicusIntroScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
