<template>
  <h6>Frage 2 von 3</h6>
  <h5 class="my-4">Wurde bei Ihnen bereits Vorhofflimmern festgestellt?</h5>

  <div class="btn-group btn-group-question mb-3" role="group">
    <input
      type="radio"
      class="btn-check"
      id="afib-y"
      :value="true"
      data-bs-toggle="modal"
      data-bs-target="#afibConfirmModal"
      v-model="store.eligibility.afib"
    />
    <label class="btn btn-outline-primary" for="afib-y">Ja</label>
    <input
      type="radio"
      class="btn-check"
      id="afib-n"
      :value="false"
      v-model="store.eligibility.afib"
      @click="store.step++"
    />
    <label class="btn btn-outline-primary" for="afib-n">Nein</label>
  </div>

  <MedicusButton
    :message="'Vorhofflimmern (AFib) ist eine Art von unregelmäßigem Herzrhythmus, bei dem das Herz aus dem Takt gerät.'"
  />
  <div v-if="store.eligibility.afib !== null">
    <button
      v-if="!store.eligibility.afib"
      @click.prevent="store.step++"
      :disabled="store.eligibility.afib === null"
      class="btn btn-proceed btn-lg w-100 mt-3"
    >
      Weiter
    </button>
    <button
      v-else
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#afibConfirmModal"
      :disabled="store.eligibility.afib === null"
      class="btn btn-proceed btn-lg w-100 mt-3"
    >
      Weiter
    </button>
  </div>
  <AfibConfirmModal />
</template>

<script setup>
import MedicusButton from "@/components/utils/MedicusButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
import AfibConfirmModal from "@/components/utils/AfibConfirmModal.vue";
const store = dhRegistrationStore();
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
