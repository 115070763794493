<template>
  <div class="row my-5 justify-content-center">
    <div class="col-5 text-center">
      <a
        ref="popoverLink"
        tabindex="0"
        data-bs-toggle="popover"
        data-bs-placement="bottom"
        data-bs-offset="0px,50px"
        :data-bs-content="message"
        id="example"
        @click="togglePopover"
      >
        <img src="img/medicusQ.png" class="img-responsive fit-image" />
      </a>
    </div>
    <div class="col-7 align-items-center d-flex text-center">
      Nicht sicher? <br />Fragen Sie Dr. Medicus.
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Popover } from "bootstrap";

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const popoverLink = ref(null);
    let popoverInstance = null;

    const togglePopover = (event) => {
      event.stopPropagation();
      if (popoverInstance) {
        popoverInstance.toggle();
      }
    };

    const hidePopover = () => {
      if (popoverInstance) {
        popoverInstance.hide();
      }
    };

    onMounted(() => {
      if (popoverLink.value) {
        popoverInstance = new Popover(popoverLink.value, {
          trigger: "manual",
        });
      }
      document.addEventListener("click", hidePopover);
    });

    return {
      popoverLink,
      togglePopover,
    };
  },
};
</script>
<style scoped>
.fit-image {
  max-height: 100px;
}
</style>
