<template>
  <transition>
    <section v-if="store.step === 0">
      <WelcomeScreen /><br />
      <button
        @click.prevent="store.step++"
        class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
      >
        Registrierung starten
      </button>
    </section></transition
  >

  <transition>
    <section v-if="store.step === 1">
      <WelcomeVideo />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 2">
      <AgeScreenStart />
      <!-- <NextButton /> -->
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 3">
      <AgeScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 4">
      <AgeConfirmationScreen />
    </section>
  </transition>
</template>

<script setup>
import WelcomeScreen from "@/components/screens/start/WelcomeScreen.vue";
import AgeScreenStart from "@/components/screens/start/AgeScreenStart.vue";
import AgeScreen from "@/components/screens/start/AgeScreen.vue";
import AgeConfirmationScreen from "@/components/screens/start/AgeConfirmationScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import WelcomeVideo from "@/components/screens/start/WelcomeVideo.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
