<template>
  <h6>Frage 3 von 3</h6>
  <h5 class="my-4">Nehmen Sie aktuell Blutverdünner?</h5>
  <h6>z.B. Eliquis, Lixiana, Xarelto, Pradaxa, Sintrom, Marcoumar</h6>
  <div class="btn-group btn-group-question mb-3" role="group">
    <input
      type="radio"
      class="btn-check"
      id="anticoagulant-y"
      :value="true"
      @click="
        store.eligibility.anticoagulant = '';
        store.step++;
      "
      v-model="store.eligibility.anticoagulantBool"
    />
    <label class="btn btn-outline-primary" for="anticoagulant-y">Ja</label>
    <input
      type="radio"
      class="btn-check"
      id="anticoagulant-n"
      :value="false"
      @click="
        store.eligibility.anticoagulant = 'none';
        store.step += 2;
      "
      v-model="store.eligibility.anticoagulantBool"
    />
    <label class="btn btn-outline-primary" for="anticoagulant-n">Nein</label>
  </div>

  <MedicusButton
    :message="'Blutverdünner sind Medikamente, die zur Verhinderung von Blutgerinnseln beitragen. Sie werden Personen verabreicht, die ein erhöhtes Risiko für Blutgerinnsel haben. Beispiele sind Xarelto (Rivaroxaban), Pradaxa (Dabigatran), Eliquis (Apixaban) und Lixiana (Edoxaban).'"
  />
  <button
    v-if="store.eligibility.anticoagulantBool !== null"
    @click.prevent="checkAnticoagulant()"
    :disabled="store.eligibility.anticoagulantBool === null"
    class="btn btn-proceed btn-lg w-100 mt-3"
  >
    Weiter
  </button>
</template>

<script setup>
import MedicusButton from "@/components/utils/MedicusButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

function checkAnticoagulant() {
  if (store.eligibility.anticoagulantBool) {
    store.step++;
  } else {
    store.step += 2;
  }
}
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
