<template>
  <h2>Randomisierung</h2>
  <h5 class="my-4">Danke, dass Sie die Fragen beantwortet haben!</h5>
  <div class="text-center">
    Sie werden nun nach dem Zufallsprinzip der aktiven Studiengruppe oder der
    Vergleichsgruppe zugeteilt. Die Einteilung erfolgt nach dem Münzwurfprinzip,
    sodass Sie eine 50% Chance haben, am aktiven Studienarm teilnehmen zu
    können. Viel Glück!
  </div>
  <div class="row justify-content-center mt-4">
    <div class="col-8 col-md-5">
      <img src="img/random.png" class="img-responsive fit-image" />
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}
</style>
