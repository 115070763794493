<template>
  <h5 class="my-3">{{ props.question.text }}</h5>
  <div class="btn-group btn-group-question mb-3" role="group">
    <input
      type="radio"
      class="btn-check"
      :id="props.question.fieldName + '-y'"
      :value="true"
      v-model="localValue"
      @change="updateStore"
    />
    <label
      class="btn btn-outline-primary"
      :for="props.question.fieldName + '-y'"
      >Ja</label
    >
    <input
      type="radio"
      class="btn-check"
      :id="props.question.fieldName + '-n'"
      :value="false"
      v-model="localValue"
      @click="store.step++"
      @change="updateStore"
    />
    <label
      class="btn btn-outline-primary"
      :for="props.question.fieldName + '-n'"
      >Nein</label
    >
  </div>

  <div v-if="localValue === true" class="mt-4">
    <h6 v-html="props.question.extendedText"></h6>
    <div class="btn-group btn-group-question" role="group">
      <template
        v-for="(label, index) in props.question.extendedLabels"
        :key="label"
      >
        <input
          type="radio"
          class="btn-check"
          :id="props.question.fieldName + '-' + index"
          :value="props.question.extendedValues[index]"
          v-model="localExtendedValue"
          @click="store.step++"
          @change="updateStore"
        />
        <label
          class="btn btn-outline-primary"
          :for="props.question.fieldName + '-' + index"
          >{{ label }}</label
        >
      </template>
    </div>
  </div>

  <button
    v-if="
      localValue === false ||
      (localValue !== null && localExtendedValue !== null)
    "
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-5"
    :disabled="
      localValue === null || (localValue && localExtendedValue === null)
    "
  >
    Weiter
  </button>
  <MedicusButton :message="props.question.medicusMessage" />
</template>

<script setup>
import MedicusButton from "../utils/MedicusButton.vue";

import { defineProps, ref, onMounted } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
const localValue = ref(null);
const localExtendedValue = ref(null);

const props = defineProps({
  question: Object,
});

onMounted(() => {
  switch (props.question.fieldName) {
    case "smoking":
      localValue.value = store.risk.smoking;
      localExtendedValue.value = store.risk.cigerettesPerDay;
      break;
    case "alcohol":
      localValue.value = store.risk.alcohol;
      localExtendedValue.value = store.risk.drinksPerDay;
      break;
    case "regularlyActive":
      localValue.value = store.risk.regularlyActive;
      localExtendedValue.value = store.risk.activityPerWeek;
      break;
    default:
      localValue.value = null;
  }
});

function updateStore() {
  if (localValue.value === false) {
    localExtendedValue.value = null;
  }
  switch (props.question.fieldName) {
    case "smoking":
      store.risk.smoking = localValue.value;
      store.risk.cigerettesPerDay = localExtendedValue.value;
      break;
    case "alcohol":
      store.risk.alcohol = localValue.value;
      store.risk.drinksPerDay = localExtendedValue.value;
      break;
    case "regularlyActive":
      store.risk.regularlyActive = localValue.value;
      store.risk.activityPerWeek = localExtendedValue.value;
      break;
    default:
      return null;
  }
}
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
