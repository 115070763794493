<template>
  <h5 class="my-3">
    Wurde bei Ihnen eine andere Gefäßerkrankung festgestellt?
  </h5>

  <div class="">
    <div class="btn-group btn-group-question mb-2 mx-0 w-100" role="group">
      <input
        type="radio"
        class="btn-check"
        id="angiopathy-y"
        value="yes"
        @click="store.step++"
        v-model="store.risk.angiopathy"
      />
      <label class="btn btn-outline-primary" for="angiopathy-y">Ja</label>
      <input
        type="radio"
        class="btn-check"
        id="angiopathy-n"
        value="no"
        @click="store.step++"
        v-model="store.risk.angiopathy"
      />
      <label class="btn btn-outline-primary" for="angiopathy-n">Nein</label>
    </div>
    <input
      type="radio"
      class="btn-check"
      id="angiopathy-m"
      value="unsure"
      @click="store.step++"
      v-model="store.risk.angiopathy"
    />
    <label class="btn btn-outline-primary" for="angiopathy-m"
      >Ich bin mir nicht sicher.</label
    >
  </div>
  <button
    v-if="store.risk.angiopathy !== ''"
    @click.prevent="store.step++"
    :disabled="store.risk.angiopathy === null"
    class="btn btn-proceed btn-lg w-100 mt-5"
  >
    Weiter
  </button>
  <MedicusButton :message="'Platzhalter'" />
</template>

<script setup>
import MedicusButton from "@/components/utils/MedicusButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
