<template>
  <h2 class="mt-4">Lassen Sie uns starten!</h2>
  <div class="row my-3 text-center">
    Um zu überprüfen, ob Sie für die Teilnahme in Frage kommen, werden wir Ihnen
    zu Beginn 3 kurze Fragen stellen.
  </div>
  <div class="row my-4 justify-content-center">
    <font-awesome-icon icon="fa-clock" class="mb-2" style="font-size: 2em" />
    Dauer: 3 Minuten
  </div>

  <h6 class="text-center mt-5">Bitte wählen Sie Ihren Standort aus:</h6>
  <div class="btn-group btn-group-question mb-4">
    <input
      type="radio"
      class="btn-check"
      id="location-ibk"
      value="Innsbruck"
      v-model="store.subject.evaluationLocation"
    />
    <label class="btn btn-outline-primary" for="location-ibk">Innsbruck</label>
    <input
      type="radio"
      class="btn-check"
      id="location-grz"
      value="Graz"
      v-model="store.subject.evaluationLocation"
    />
    <label class="btn btn-outline-primary" for="location-grz">Graz</label>
  </div>

  <button
    @click.prevent="store.step++"
    :disabled="store.subject.evaluationLocation === ''"
    class="btn btn-proceed btn-lg w-100 mt-3"
  >
    Weiter
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
