<template>
  <h3 class="text-center mb-3 mt-4">Geschlecht</h3>
  <div class="btn-group btn-group-registration w-100 mb-4" role="group">
    <input
      type="radio"
      class="btn-check"
      id="male"
      value="male"
      v-model="store.subject.sex"
    />
    <label class="btn btn-outline-primary" for="male">Männlich</label>
    <input
      type="radio"
      class="btn-check"
      id="female"
      value="female"
      v-model="store.subject.sex"
    />
    <label class="btn btn-outline-primary" for="female">Weiblich</label>
    <input
      type="radio"
      class="btn-check"
      id="other"
      value="other"
      v-model="store.subject.sex"
    />
    <label class="btn btn-outline-primary" for="other">Divers</label>
  </div>

  <button
    class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
    :disabled="store.subject.sex === ''"
    @click.prevent="store.step++"
  >
    Weiter
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
