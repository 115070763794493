<template>
  <h3 class="text-center mb-3">
    Geburtsdatum & <br />
    Sozialversicherungsnr.
    <h6>
      (dev only:
      <button @click.prevent="generateMockUp" class="btn btn-success p-2 mx-1">
        <font-awesome-icon icon="user-plus" class="px-0" />
      </button>
      <button @click.prevent="emptyForm" class="btn btn-danger p-2 mx-1">
        <font-awesome-icon icon="user-minus" class="px-0" /></button
      >)
    </h6>
  </h3>
  <form
    class="needs-validation"
    @submit.prevent="validateConsentData"
    novalidate
  >
    <label class="form-label mb-0"><h6>Geburtsdatum wählen:</h6></label>
    <VueDatePicker
      v-model="store.subject.dateOfBirth"
      :flow="dateFlow"
      locale="de"
      auto-apply
      :enable-time-picker="false"
      :max-date="new Date()"
      :start-date="startDate"
      class="mb-4 date-picker"
      :format="format"
    />

    <div class="form-floating mb-4">
      <input
        type="number"
        class="form-control"
        id="socialSecurity"
        placeholder="123412345678"
        :class="[
          store.temp.socialSecurityError == 1 ? 'is-valid' : '',
          store.temp.socialSecurityError > 1 ? 'is-invalid' : '',
        ]"
        v-model="store.subject.socialSecurity"
      />
      <label for="socialSecurity">Sozialversicherungsnummer</label>
      <div class="invalid-feedback" v-if="store.temp.socialSecurityError === 2">
        Bitte geben Sie eine 10-stellige Sozialversicherungsnummer an.
      </div>
      <div class="invalid-feedback" v-if="store.temp.socialSecurityError === 3">
        Geburtsdatum und Sozialversicherungsnr. passen nicht zusammen.
      </div>
      <div class="invalid-feedback" v-if="store.temp.socialSecurityError === 4">
        Bitte geben Sie eine gültige Sozialversicherungsnummer an (nur Zahlen).
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
      :disabled="isFormInvalid"
    >
      Weiter
    </button>
  </form>
  <AgeChangeModal />
</template>

<script setup>
import AgeChangeModal from "@/components/utils/AgeChangeModal.vue";
import { Modal } from "bootstrap";
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed, onMounted } from "vue";
import { samplePersons } from "@/assets/samplePersons";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
const startDate = ref(new Date(1950, 1));
const dateFlow = ref(["year", "month", "calendar"]);
const store = dhRegistrationStore();

const isFormInvalid = computed(() => {
  return (
    store.subject.dateOfBirth === "" ||
    store.subject.dateOfBirth === null ||
    store.subject.socialSecurity === ""
  );
});

const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}. ${month}. ${year}`;
};

function validateSocialSecurity() {
  store.temp.socialSecurityError = 1;

  var socialSecurityArray = store.subject.socialSecurity
    .toString(10)
    .split("")
    .map(Number);

  if (
    store.temp.socialSecurityError === 1 &&
    socialSecurityArray.length !== 10
  ) {
    store.temp.socialSecurityError = 2;
  }

  if (store.temp.socialSecurityError === 1) {
    if (typeof store.subject.dateOfBirth === "string") {
      store.subject.dateOfBirth = new Date(store.subject.dateOfBirth);
    }
    const dobYear = parseInt(
      store.subject.dateOfBirth.getUTCFullYear().toString().slice(-2)
    );
    const dobMonth = store.subject.dateOfBirth.getMonth() + 1;
    const dobDay = store.subject.dateOfBirth.getDate();

    const enteredSocialSecurity = store.subject.socialSecurity.toString();

    const lastSixDigits = enteredSocialSecurity.slice(-6);

    const socialYear = parseInt(lastSixDigits.slice(4), 10);
    const socialMonth = parseInt(lastSixDigits.slice(2, 4), 10);
    const socialDay = parseInt(lastSixDigits.slice(0, 2), 10);
    if (
      dobYear !== socialYear ||
      dobMonth !== socialMonth ||
      dobDay !== socialDay
    ) {
      store.temp.socialSecurityError = 3;
    }
  }

  if (store.temp.socialSecurityError === 1) {
    var checkDigit = socialSecurityArray[3];
    var numbersToCheck = socialSecurityArray
      .slice(0, 3)
      .concat(socialSecurityArray.slice(4));
    var controlNumbers = [3, 7, 9, 5, 8, 4, 2, 1, 6];
    var checkSum = 0;
    for (let i = 0; i < numbersToCheck.length; i++) {
      checkSum += numbersToCheck[i] * controlNumbers[i];
    }
    checkSum %= 11;
    if (checkSum !== checkDigit) {
      store.temp.socialSecurityError = 4;
    }
  }
}

function validateAge() {
  var dateOfBirth = new Date(store.subject.dateOfBirth);
  var ageDiff = Date.now() - dateOfBirth.getTime();
  var ageDate = new Date(ageDiff);
  store.temp.ageEntered = Math.abs(ageDate.getUTCFullYear() - 1970);
  if (Math.abs(store.temp.ageEntered - store.subject.age) > 1) {
    store.temp.ageValidationError = 1;
  } else {
    store.temp.ageValidationError = 2;
  }
}

function validateConsentData() {
  validateSocialSecurity();
  validateAge();

  if (
    store.temp.ageValidationError === 2 &&
    store.temp.socialSecurityError === 1
  ) {
    if (store.subject.age < 65) {
      store.abortAgeError = 1;
      store.step = 0;
    } else {
      store.subject.dateOfBirth = store.subject.dateOfBirth
        .toISOString()
        .split("T")[0];
      store.step++;
    }
  } else if (store.temp.ageValidationError == 1) {
    const ageChangeModalEl = document.getElementById("ageChangeModal");
    const ageChange = new Modal(ageChangeModalEl);
    ageChange.show();
  }
}

function generateMockUp() {
  let randomPerson =
    samplePersons[Math.floor(Math.random() * samplePersons.length)];
  store.subject.dateOfBirth = randomPerson.dateOfBirth;
  store.subject.socialSecurity = randomPerson.socialSecurity;
}

function emptyForm() {
  store.subject.dateOfBirth = "";
  store.subject.socialSecurity = "";
}

onMounted(() => {
  store.temp.ageValidationError = 0;
});
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>

<style>
.dp__input {
  height: 65px !important;
}
</style>
