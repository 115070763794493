<template>
  <div
    class="modal fade"
    id="afibConfirmModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Vorhofflimmern bestätigen</h5>
        </div>
        <div class="modal-body">
          <h6 class="mb-4">
            Sind Sie sicher, dass bei Ihnen Vorhofflimmern diagnostiziert wurde?
          </h6>
          Vorhofflimmern kann nur durch einen Arzt festgestellt werden und wird
          oft mit Blutverdünnung behandelt.
        </div>
        <div class="modal-footer py-3">
          <button
            type="button"
            class="btn btn-outline-dark w-100"
            data-bs-dismiss="modal"
            @click="
              store.eligibility.afib = false;
              store.step++;
            "
          >
            Nein, ich habe doch kein Vorhofflimmern.
          </button>

          <button
            type="button"
            class="btn btn-danger w-100"
            data-bs-dismiss="modal"
            @click="
              store.eligibility.afib = true;
              store.abortEligibilityError = 1;
              store.step = 0;
            "
          >
            Ja, ich habe Vorhofflimmern.
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
