<template>
  <h2>Gratulation!</h2>
  <div class="text-center info-text">
    <span>
      Sie erfüllen alle Voraussetzungen, um am wissenschaftlichen Projekt
      <b>Pulskontrolle</b> teilzunehmen!<br />
      <br />
      Schauen Sie sich auf der nächsten Seite ein Video an, das Sie über die
      Teilnahme informiert.<br
    /></span>
  </div>
  <button
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
  >
    Zum Video<font-awesome-icon icon="fa-circle-arrow-right" class="px-3" />
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.info-text {
  font-size: 14px;
}
</style>
